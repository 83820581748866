import { waitForElementToBeRemoved } from "@testing-library/react";
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useCookies } from "react-cookie";
import { useResizeDetector } from "react-resize-detector";
import "./conway.css";
import conwayPatterns from "./patterns.json";
import PhotoWarning from "./PhotoWarning";

const CELL_SIZE = 20;
const STEP_SIZE = 100;
const ALIVE_COLOR = "#505050";
const RANDOMIZE_DENSITY = 0.5;

const epilepsyCookie = "epilepsy-disable";

let last = 0;

function debounce(method, delay) {
    if(Date.now() - last > delay) {
        method();
        last = Date.now();
    }
}


export default function ConwayCanvas() {
    const [cookies, setCookie, removeCookie] = useCookies([epilepsyCookie]);
    const [showEffects, setShowEffects] = useState(true);
    const [showWarning, setShowWarning] = useState(cookies[epilepsyCookie] == undefined);
    const [initialized, setInitialized] = useState(false);
    const [resizeTimeout, setResizeTimeout] = useState();
    const [prevRatio, setPrevRatio] = useState();

    const { width, height, ref } = useResizeDetector();
    const canvasRef = useRef(null);

    const gameGrid = [[false]];
    window.gameGrid = gameGrid; //  DEBUG

    useLayoutEffect(() => {
        document.querySelector(".contained").addEventListener("scroll", onScroll);

        return () => document.querySelector(".contained").removeEventListener('scroll', onScroll)
    }, [width]);


    function disableEffects() {
        const expire = new Date(new Date().getTime() + 86400000)
        setCookie(epilepsyCookie, "true", { expires: expire });
        setShowEffects(false);
        setShowWarning(false);
    }

    function enableEffects() {
        const expire = new Date(new Date().getTime() + 86400000)
        setCookie(epilepsyCookie, "false", { expires: expire });
        setShowEffects(true);
        setShowWarning(false);
    }

    function resize(width, height) {
        const rows = Math.ceil(height / CELL_SIZE);
        const cols = Math.ceil(width / CELL_SIZE);

        if (gameGrid.length < rows) {
            for (let i = 0; i < rows; ++i) {
                gameGrid.push(new Array(cols).fill(false))
            }
        }

        for (let i = 0; i < rows; ++i) {
            if (gameGrid.length > 0 && gameGrid[i].length < cols)
                gameGrid[i].push(...new Array(cols).fill(false))
        }
    }

    function placePattern(x, y, patternName) {
        const pattern = conwayPatterns[patternName];
        if (!pattern) return;
        if (x + pattern[0].length >= gameGrid[0].length || y + pattern.length >= gameGrid.length) return;

        for (let i = 0; i < pattern.length; ++i) {
            for (let j = 0; j < pattern[0].length; ++j) {
                gameGrid[y + i][x + j] = pattern[i][j];
            }
        }
    }

    function initialize() {
        resize(width, height);
        if (showEffects) {
            randomize(RANDOMIZE_DENSITY);
            for (let i = 0; i < 50; ++i)
                stepSimulation();
        }
        draw(canvasRef.current.getContext("2d"));
    }

    function stepSimulation() {
        const oldState = JSON.parse(JSON.stringify(gameGrid));

        for (let i = 0; i < gameGrid.length; ++i) {
            for (let j = 0; j < gameGrid[i].length; ++j) {
                let neighborCount = 0;
                const alive = oldState[i][j];

                for (let k = -1; k <= 1; ++k) {
                    for (let l = -1; l <= 1; ++l) {
                        if (k == 0 && l == 0) continue;

                        if (i + k < gameGrid.length && i + k >= 0 && j + l < gameGrid[i].length && j + l >= 0 && oldState[i + k][j + l]) {
                            neighborCount++;
                        }
                    }
                }

                if ((alive && neighborCount < 2) || (alive && neighborCount > 3)) {
                    gameGrid[i][j] = false;
                } else if (!alive && neighborCount == 3) {
                    gameGrid[i][j] = true;
                }
            }
        }
    }

    function randomize(density) {
        for (let i = 0; i < gameGrid.length; ++i) {
            for (let j = 0; j < gameGrid[i].length; ++j) {
                if (Math.random() < density) {
                    gameGrid[i][j] = true;
                } else {
                    gameGrid[i][j] = false;
                }
            }
        }
    }

    function draw(canvasContext) {
        canvasContext.beginPath();
        canvasContext.lineWidth = 2.5;
        canvasContext.strokeStyle = "#171717"

        // draw row lines
        for (let i = 0; i < gameGrid.length; ++i) {
            canvasContext.moveTo(0, CELL_SIZE * i);
            canvasContext.lineTo(canvasContext.canvas.width, CELL_SIZE * i);
        }

        // draw column lines
        for (let i = 0; i < gameGrid[0].length; ++i) {
            canvasContext.moveTo(CELL_SIZE * i, 0);
            canvasContext.lineTo(CELL_SIZE * i, canvasContext.canvas.height);
        }

        // fill
        for (let i = 0; i < gameGrid.length; ++i) {
            for (let j = 0; j < gameGrid[i].length; j++) {
                if (gameGrid[i][j]) canvasContext.fillStyle = ALIVE_COLOR;
                else canvasContext.fillStyle = "#000";

                canvasContext.fillRect(j * CELL_SIZE, i * CELL_SIZE, CELL_SIZE, CELL_SIZE);
            }
        }

        canvasContext.stroke();
    }

    function onScroll() {
        if (!showEffects) return;

        debounce(() => {
            stepSimulation();
            draw(canvasRef.current.getContext("2d"));
        }, 70);
    }

    useEffect(() => {
        if (prevRatio && width / height == prevRatio) return;

        const canvas = canvasRef.current;
        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext("2d");
        context.fillStyle = '#000000'
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);

        if (!initialized && width > 0) {
            initialize();

            setInitialized(true);
        } else {
            clearTimeout(resizeTimeout);
            setResizeTimeout(setTimeout(initialize, 50));
        }

        setPrevRatio(width / height);
    }, [width, height]);

    useEffect(initialize, [showEffects]);

    return <>
        {/* {showWarning && <PhotoWarning disableEffects={disableEffects} enableEffects={enableEffects} />} */}
        <div ref={ref} className="conway-canvas">
            <canvas ref={canvasRef} width="100%" height="100%">

            </canvas>
        </div>
    </>
}