import { Col, Image, Row } from "react-bootstrap"
import headshotImage from "./headshot.jpg"
import "./css/aboutMe.css"

export default function AboutMe() {
    return (
        <div id="about">
            <h2>About Me</h2>
            <div className="content">
                <Row className="gy-3">
                    <Col md={8}>
                        <div className="textContainer">
                            <p>Hello! I'm <b>Jaeheon</b> (he/him), a passionate self-taught software developer and <b>computer science</b> enthusiast. Aside from creating websites like this one with <b>Javascript</b> and <b>React</b>, my main interests include the study of <b>data structures and algorithms</b>, and the <b>simulation</b> of mathematical phenomena using computer science. (Who doesn't love satisfying animations?)</p>
                            <p>I'm primarily proficient in the <b>Java</b> programming language, but I have experience working with <b>Javascript</b>, <b>Python</b>, and <b>C++</b> as well. Aside from computer programming, I love making things with my hands and building robots. I have considerable experience modeling in <b>Autodesk Fusion 360</b>. Furthermore <b>3D printing</b> and <b>CNC</b> and are among my favorite hobbies.</p>
                            <p>I'm currently majoring in computer science with a minor in mathematics at the <b>Georgia Institute of Technology.</b> I plan to graduate in the Spring of 2026. My long-term dream is to pursue research in the field of computer science.</p>
                            <p>When I'm away from the computer, I enjoy reading. <a target="_blank" href="https://en.wikipedia.org/wiki/Ted_Chiang">Ted Chiang's</a> short stories are fabulous! My other pastimes include chess and piano.</p>
                            <p>
                                If you're interested in learning more about me, below are a few links.
                            </p>
                            <ul>
                                <li><a target="_blank" href="https://www.linkedin.com/in/jaeheonshim/">LinkedIn</a>: My LinkedIn profile</li>
                                <li><a target="_blank" href="https://github.com/jaeheonshim">Github</a>: Most of my publishable projects are located here</li>
                                <li><a target="_blank" href="https://devpost.com/jaeheonshim">Devpost</a>: Hackathon stuff</li>
                                <li><a target="_blank" href="https://dev.to/jaeheonshim">DEV</a>: My dev.to profile</li>
                            </ul>
                            <p>
                                And please don't hesitate to <a href="#contact">contact me</a>!
                            </p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div style={{ width: "100%", minWidth: "400px" }}>
                            <Image width="100%" src={headshotImage} rounded />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}