import { useState } from "react"
import "./css/contact.css"
import challengeImage from "./challenge.png"

const email = "YW1GbGFHVnZiaTV6YUdsdFFHZGhkR1ZqYUM1bFpIVT0=";

export default function ContactMe() {
    const [emailText, setEmailText] = useState("Reveal email address");
    const [questionValue, setQuestionValue] = useState();

    const onChange = (e) => {
        setQuestionValue(e.target.value);
    };

    const reveal = () => {
        if(questionValue.trim().toLowerCase() == "4") {
            setEmailText(atob(atob(email)));
        }
    }

    return (
        <div id="contact">
            <h2>Contact Me</h2>
            <div className="content">
                <div className="textContainer">
                    <p>
                    The best way to contact me is through email. Please follow the directions below to reveal my email address: (this is done to combat web scraping and spam)
                    </p>

                    <div>
                        <img src={challengeImage} /> <input onChange={onChange} type="text"></input>
                        <div onClick={reveal} id="revealEmail">{emailText}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}