import "./css/projects.css"
import { Col, Image, Row } from "react-bootstrap"
import ProjectCard from "./ProjectCard"
import projectData from "./projectData";
import ProjectModal from "./ProjectModal";
import { useState } from "react";

const projectList = [
    "tdraw",
    "bsv",
    "sandbox",
    "dpend",
    "lifespan",
    "coastalbrief",
    "arduino-combolock",
    "paste",
    "ers",
    "tic4",
    "pixeltanks",
    "towerheist",
    "tetrisgdx",
    "arduino-tetris",
    "uniongrid",
    "discordcah",
    "habithelper",
    "pixelrun",
    "mealmaker",
    "chessboard",
    "frcapi",
    "assignmentapp",
    "thetitanteam",
    "oca"
];

export default function Projects() {
    const content = [];

    const [selectedProject, setSelectedProject] = useState(null)

    for(let i = 0; i < projectList.length; i += 3) {
        const row = [];
        for(let j = 0; j < 3 && i + j < projectList.length; ++j) {
            if(!projectData[projectList[i + j]]) continue;

            row.push(<Col onClick={() => setSelectedProject(projectData[projectList[i + j]])} md={4}><ProjectCard id={projectList[i + j]} /></Col>);
        }

        content.push(<Row className="gy-5">{row}</Row>);
    }

    return (
        <div>
            <ProjectModal handleClose={() => setSelectedProject(null)} project={selectedProject} />
            <div className="content">
                {content}
            </div>
        </div>
    )
}