import { Badge, Button, Modal } from "react-bootstrap";

export default function ProjectModal({ project, handleClose }) {
    return (
        <>
            <Modal size="xl" show={project} onHide={handleClose}>
                {project ? <>
                    <Modal.Header closeButton>
                        <Modal.Title>{project.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{__html: project.description || "This project currently has no description."}}>
                        </div>
                        <div className="tags"> Technologies: { project.tags ? (project.tags).map(t => <Badge bg="primary">{t}</Badge>) : "This project currently has no tags." } </div>
                        { project.link && <Button style={{ marginTop: "1em", float: "right" }} href={project.link} target="_blank">Open Project</Button> }
                    </Modal.Body>
                </> : <></>}
            </Modal>
        </>
    );
}