import discordCahImage from "./project-photos/discordcah.png"
import tdrawImage from "./project-photos/tdraw.png"
import bsvImage from "./project-photos/bsv.png"
import tetrisGdxImage from "./project-photos/tetrisgdx.png"
import dpendImage from "./project-photos/dpend.gif"
import unionGridImage from "./project-photos/uniongrid.png"
import chessBoardImage from "./project-photos/chess.jpeg"
import tic4Image from "./project-photos/tic4.png"
import ersImage from "./project-photos/ers.png"
import towerHeistImage from "./project-photos/towerheist.png"
import theTitanTeamImage from "./project-photos/thetitanteam.png"
import frcApiImage from "./project-photos/frcapi.png"
import pasteImage from "./project-photos/paste.png"
import coastalBriefImage from "./project-photos/coastalbrief.png"
import sandboxImage from "./project-photos/sandbox.gif"
import arduinoLockImage from "./project-photos/arduinolock.gif"
import arduinoTetrisImage from "./project-photos/arduinotetris.gif"
import lifespanImage from "./project-photos/lifespan.png"
import mealmakerImage from "./project-photos/mealmaker.png"
import pixeltanksImage from "./project-photos/pixeltanks.png"
import habithelperImage from "./project-photos/habithelper.png"
import pixelrunImage from "./project-photos/pixelrun.gif"
import assignmentappImage from "./project-photos/assignmentapp.png"
import ocaImage from "./project-photos/oca.jpg"

export default {
    discordcah: {
        title: "DiscordCAH",
        image: discordCahImage,
        description: "<p>A chatbot facilitating gameplay of the popular game 'Cards Against Humanity' on the Discord messaging platform. Used by hundreds of thousands of users in over 20,000 different servers.</p><p>Handling hundreds of requests concurrently proved to be a difficult challenge. To solve this problem, a two-part backend infrastructure is used. The Discord.js frontend communicates via HTTP to an Express.js backend API. The backend API uses an in-memory Redis database to manage in-progress games, and uses mongoose.js to communicate with a MongoDB database to save persistent user data. This allows for an infrastructure that is resilient even if the application stops working in certain regions (i.e. certain 'shards' crash ungracefully).</p>",
        tags: ["Node.js", "TypeScript", "discord.js", "MongoDB", "axios", "Express.js", "Redis"]
    },
    tdraw: {
        title: "TDraw",
        image: tdrawImage,
        description: "<p>A web platform for creating Turtle graphics using the Java programming language. I created this project to teach Java to students in the Mill Creek High School Computer Science Club.</p><p>To solve the problem of running Java code in the browser, this project is divided into three parts. The first part is a custom built Java Turtle graphics library, which provides traditional functions for manipulating the turtle object on the screen, as well as setting properties such as color. This library interacts with the Node.JS server, which sends user code along with the library to a Judge0 instance, which executes the code and returns a set of drawing coordinates. Finally, these coordinates are sent to the React frontend, where it is rendered for the user.</p>",
        tags: ["Node.js", "Javascript", "Java", "React", "Bootstrap", "Judge0"],
        link: "https://tdraw.jaeheonshim.dev/"
    },
    bsv: {
        title: "BetterStudentVue",
        image: bsvImage
    },
    tetrisgdx: {
        title: "TetrisGDX",
        image: tetrisGdxImage
    },
    dpend: {
        title: "Double Pendulums",
        image: dpendImage
    },
    uniongrid: {
        title: "UnionGrid",
        image: unionGridImage
    },
    chessboard: {
        title: "ChessBoard",
        image: chessBoardImage
    },
    tic4: {
        title: "TI-Connect4",
        image: tic4Image
    },
    ers: {
        title: "ERS",
        image: ersImage
    },
    towerheist: {
        title: "TowerHeist",
        image: towerHeistImage
    },
    thetitanteam: {
        title: "TheTitanTeam",
        image: theTitanTeamImage
    },
    frcapi: {
        title: "FRCAPI4J",
        image: frcApiImage
    },
    paste: {
        title: "PASTE",
        image: pasteImage
    },
    coastalbrief: {
        title: "CoastalBrief",
        image: coastalBriefImage
    },
    sandbox: {
        title: "Sandbox",
        image: sandboxImage
    },
    "arduino-combolock": {
        title: "Arduino-Combolock",
        image: arduinoLockImage
    },
    lifespan: {
        title: "Lifespan",
        image: lifespanImage
    },
    mealmaker: {
        title: "Random Meal Generator",
        image: mealmakerImage
    },
    pixeltanks: {
        title: "PixelTanks",
        image: pixeltanksImage
    },
    "arduino-tetris": {
        title: "Arduino-Tetris",
        image: arduinoTetrisImage
    },
    habithelper: {
        title: "HabitHelper",
        image: habithelperImage
    },
    pixelrun: {
        title: "Pixel Run",
        image: pixelrunImage
    },
    assignmentapp: {
        title: "AssignmentApp",
        image: assignmentappImage
    },
    oca: {
        title: "Oracle Certified",
        image: ocaImage
    }
};