import { useEffect } from "react";
import { Container, useAccordionButton } from "react-bootstrap";
import "./css/header.css";

export default function Header(props) {
    function scrollToProjects() {
        props.accordionSetActiveKey("0");
        setTimeout(() => props.projectAccordionRef.current.scrollIntoView(), 100);
    }

    return (
    <div className="header">
        <div className="content">
            <Container>
                <div style={{float: "left"}}>
                    <div className="sideText">Hi, I'm</div>
                    <h1>Jaeheon Shim</h1>
                    <div className="sideText">I write software and study computer science.</div>
                </div>
                <div style={{float: "right"}}>
                    <a href="#about">About Me</a>
                    <br />
                    <a href="#" onClick={scrollToProjects}>Projects</a>
                    <br />
                    <a href="#contact">Contact Me</a>
                </div>
            </Container>
        </div>

        <div className="aocwidget" data-url="https://aocwidget-personal.jaeheonshim.dev/widget?highestDay=1" style={{display: "inline-block", position: "absolute", top: "0.5em", right: "0.5em"}}></div>
    </div>
    )
}