import AboutMe from './Content/AboutMe';
import ContactMe from './Content/ContactMe';
import Header from './Content/Header';
import Projects from './Content/Projects';
import ConwayCanvas from './Conway/ConwayCanvas';
import ReactScrollDetector from 'react-scroll-detector'
import { useRef, useState } from 'react';
import { Accordion, Container, useAccordionButton } from 'react-bootstrap';

export default function Home() {
    const [activeKey, setActiveKey] = useState(undefined);
    const projectAccordionRef = useRef(null);

    return (
        <div className="App">
            <ConwayCanvas />
            <div className="contained">
                <Header accordionSetActiveKey={setActiveKey} projectAccordionRef={projectAccordionRef} />
                <Container>
                    <AboutMe />
                    <Accordion activeKey={activeKey} flush>
                        <Accordion.Item ref={projectAccordionRef} id="projects" eventKey="0">
                            <Accordion.Header onClick={() => setActiveKey(undefined)}><h2>Projects</h2></Accordion.Header>
                            <Accordion.Body>
                                <Projects />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <ContactMe />
                </Container>
            </div>
        </div>
    );
}