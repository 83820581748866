function StyledLink({ text, href }) {
    return <div>
        <a href={href} className="text-xl no-underline block bg-blue-600 font-bold text-center hover:bg-blue-700 text-white py-3 rounded-lg">
            {text}
        </a>
    </div>
}

function Divider({ text }) {
    return <div className="relative flex items-center mt-4">
        <div className="flex-grow border-t border-gray-400"></div>
        <span className="flex-shrink mx-4 text-black-400">{text}</span>
        <div className="flex-grow border-t border-gray-400"></div>
    </div>
}

function Resume() {
    return <div className="container mx-auto flex flex-col items-center py-10 px-5">
        <h1>Hi, I'm Jaeheon Shim</h1>
        <p className="mt-4 mb-10 text-center">Thank you for taking the time to review my resume! Below are a few links of interest if you would like to contact me or review my portfolio.</p>
        <div className="grid gap-3 grid-cols-1 w-100">
            <StyledLink text="Download Resume as PDF" href={process.env.PUBLIC_URL + "/Resume.pdf"} />
            <Divider text="Let's Connect" />
            <StyledLink text="Connect on LinkedIn" href="https://linkedin.com/in/jaeheonshim" />
            <StyledLink text="Email" href='mailto:"Jaeheon Shim"<jaeheon.shim@gatech.edu>' />
            
            <Divider text="Portfolio" />
            <StyledLink text="GitHub Profile" href="https://github.com/jaeheonshim" />
            <StyledLink text="Personal Website" href="https://jaeheonshim.dev" />
            <StyledLink text="Devpost" href="https://devpost.com/jaeheonshim" />
        </div>
    </div>
}

export default Resume;
