import { Image } from "react-bootstrap";
import projectData from "./projectData";

export default function ProjectCard(props) {
    return (
        <div className="project-card">
            <Image className="project-image" src={props.id ? projectData[props.id].image : props.image} rounded />
            <div className="project-title rounded-bottom">
                {props.id ? projectData[props.id].title : props.title}
            </div>
        </div>
    )
}